import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Grid,
  Paper,
  IconButton,
  AppBar,
  Toolbar,
  TextField,
  Button,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  CircularProgress,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import ContactsIcon from "@mui/icons-material/Contacts";
import BarChartIcon from "@mui/icons-material/BarChart";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  getData,
  addProperty,
  deleteProperty,
  updateProperty,
} from "../api/data";
import { getContactData } from "../api/contacts";

function PanelPage() {
  const [removedImages, setRemovedImages] = useState([]);
  const [newImages, setNewImages] = useState([]); // Nuevas imáge
  const [view, setView] = useState("properties"); // Vista inicial
  const [isLoading, setIsLoading] = useState(false); // Estado general de carga
  const [property, setProperty] = useState({
    name: "",
    location: "",
    ubizone: "",
    ubidirec: "",
    type: "",
    size: "",
    price: "",
    description: "",
    services: "",
    zone: "",
    payments: "",
    images: [],
  });
  const [properties, setProperties] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [editingProperty, setEditingProperty] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProperty((prevProperty) => ({
      ...prevProperty,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (editingProperty) {
      setProperty(editingProperty);
      console.log(editingProperty);
    }
  }, [editingProperty]);

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const data = await getData();
        setProperties(data.data);
      } catch (error) {
        console.error("Error al obtener los datos:", error);
      }
    };
    fetchProperties();
  }, []);

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const datacontact = await getContactData();
        setContacts(datacontact.data);
      } catch (error) {
        console.error("Error al obtener los contactos:", error);
      }
    };
    fetchContacts();
  }, []);

  const handleRemoveImage = (index) => {
    setProperty((prevProperty) => {
      const removedImage = prevProperty.images[index]; // Guardar imagen eliminada
      setRemovedImages((prevRemoved) => [...prevRemoved, removedImage]);

      return {
        ...prevProperty,
        images: prevProperty.images.filter((_, i) => i !== index),
      };
    });
  };
  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          width: "100vj",
          backgroundColor: "#f5f5f5", // Fondo claro opcional
        }}
      >
        <Box textAlign="center">
          <CircularProgress />
          <Typography sx={{ marginTop: 2 }}>Cargando datos...</Typography>
        </Box>
      </Box>
    );
  }

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    const imageURLs = files.map((file) => URL.createObjectURL(file));

    setProperty((prevProperty) => ({
      ...prevProperty,
      images: [...prevProperty.images, ...imageURLs], // Agregar nuevas imágenes
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Inicia el indicador de carga

    try {
      const formData = new FormData();

      // Agregar otros datos del formulario excepto imágenes
      Object.entries(property).forEach(([key, value]) => {
        if (key !== "images") {
          formData.append(key, value);
        }
      });

      // Procesar imágenes actuales
      const processedImages = await Promise.all(
        property.images.map(async (imageUrl) => {
          if (imageUrl.startsWith("blob:") || imageUrl.startsWith("data:")) {
            // Nueva imagen (local blob)
            const response = await fetch(imageUrl);
            const blob = await response.blob();
            return new File([blob], "image.jpg", { type: blob.type });
          } else {
            // Imagen existente (ya en el servidor)
            return imageUrl;
          }
        })
      );

      // Agregar imágenes procesadas al formulario
      processedImages.forEach((image) => {
        if (image instanceof File) {
          formData.append("images", image); // Nueva imagen
        } else {
          formData.append("existingImages[]", image); // Imagen existente
        }
      });

      // Actualizar o agregar propiedad según sea necesario
      if (editingProperty) {
        await updateProperty(editingProperty._id, formData);
        console.log("Propiedad actualizada correctamente");
      } else {
        await addProperty(formData);
        console.log("Propiedad agregada correctamente");
      }

      // Reiniciar el estado del formulario
      setProperty({
        name: "",
        location: "",
        ubizone: "",
        ubidirec: "",
        type: "",
        size: "",
        price: "",
        description: "",
        services: "",
        zone: "",
        payments: "",
        images: [],
      });
      setEditingProperty(null);
      window.location.reload(); // Refresca la página después de enviar
    } catch (error) {
      console.error("Error al guardar la propiedad:", error);
    } finally {
      setIsLoading(false); // Detén el indicador de carga
    }
  };

  const handleDelete = async (id) => {
    setIsLoading(true); // Inicia el indicador de carga
    try {
      const response = await deleteProperty({ id });
      if (response.status === 200) {
        // Filtrar el estado actual para eliminar la propiedad eliminada
        setProperties((prevProperties) =>
          prevProperties.filter((property) => property.id !== id)
        );

        // Reiniciar la página
        window.location.reload();
      } else {
        console.error("Error al eliminar la propiedad");
      }
    } catch (error) {
      console.error("Error al eliminar la propiedad:", error);
    } finally {
      setIsLoading(false); // Detén el indicador de carga
    }
  };

  const renderView = () => {
    switch (view) {
      case "properties":
        return (
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Paper elevation={3} sx={{ padding: 3 }}>
                <Typography variant="h6">Agregar Nueva Propiedad</Typography>
                <form onSubmit={handleSubmit}>
                  {/* Nombre */}
                  <TextField
                    label="Nombre"
                    fullWidth
                    value={property.name || ""}
                    onChange={handleChange}
                    name="name"
                    margin="normal"
                    required
                  />

                  {/* Ubicación */}
                  <TextField
                    label="Ubicación Link Maps"
                    fullWidth
                    value={property.location || ""}
                    onChange={handleChange}
                    name="location"
                    margin="normal"
                    required
                  />
                  <TextField
                    label="Ubicación Zona"
                    fullWidth
                    value={property.ubizone || ""}
                    onChange={handleChange}
                    name="ubizone"
                    margin="normal"
                    required
                  />
                  <TextField
                    label="Dirección"
                    fullWidth
                    value={property.ubidirec || ""}
                    onChange={handleChange}
                    name="ubidirec"
                    margin="normal"
                    required
                  />

                  {/* Tipo */}
                  <InputLabel sx={{ mt: 2 }}>Tipo</InputLabel>
                  <Select
                    value={property.type || ""}
                    onChange={handleChange}
                    name="type"
                    fullWidth
                    displayEmpty
                    required
                  >
                    <MenuItem value="" disabled>
                      Selecciona un tipo
                    </MenuItem>
                    <MenuItem value="Residential">Residencial</MenuItem>
                    <MenuItem value="Investment">Inversión</MenuItem>
                    <MenuItem value="Production">Producción</MenuItem>
                  </Select>

                  {/* Tamaño y Precio */}
                  <TextField
                    label="Tamaño"
                    fullWidth
                    value={property.size || ""}
                    onChange={handleChange}
                    name="size"
                    margin="normal"
                    required
                  />
                  <TextField
                    label="Precio"
                    fullWidth
                    value={property.price || ""}
                    onChange={handleChange}
                    name="price"
                    margin="normal"
                    required
                  />

                  {/* Descripción */}
                  <TextField
                    label="Descripción"
                    fullWidth
                    multiline
                    rows={4}
                    value={property.description || ""}
                    onChange={handleChange}
                    name="description"
                    margin="normal"
                    required
                  />

                  {/* Servicios, Zonificación y Pagos */}
                  <TextField
                    label="Servicios"
                    fullWidth
                    value={property.services || ""}
                    onChange={handleChange}
                    name="services"
                    margin="normal"
                    required
                  />
                  <TextField
                    label="Zonificación"
                    fullWidth
                    value={property.zone || ""}
                    onChange={handleChange}
                    name="zone"
                    margin="normal"
                    required
                  />
                  <TextField
                    label="Facilidades de Pago"
                    fullWidth
                    value={property.payments || ""}
                    onChange={handleChange}
                    name="payments"
                    margin="normal"
                    required
                  />

                  {/* Archivos de imagen */}
                  <InputLabel sx={{ mt: 2 }}>Imágenes</InputLabel>
                  <input
                    type="file"
                    multiple
                    accept=".jpg,.png"
                    onChange={handleImageChange}
                    style={{ marginTop: "8px" }}
                  />

                  {/* Botón de guardar */}
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{ mt: 3 }}
                  >
                    Guardar propiedad
                  </Button>

                  {/* Mostrar imágenes seleccionadas */}
                  <Box mt={2} display="flex" flexWrap="wrap" gap={1}>
                    {property.images.map((image, index) => (
                      <Box
                        key={`existing-${index}`}
                        position="relative"
                        display="inline-block"
                      >
                        <img
                          src={`https://andesconectionsbackend.onrender.com/${image}`}
                          alt={`Imagen ${index}`}
                          width="100"
                          style={{ borderRadius: "5px" }}
                        />
                        <IconButton
                          size="small"
                          style={{
                            position: "absolute",
                            top: "-5px",
                            right: "-5px",
                            background: "rgba(255, 255, 255, 0.8)",
                          }}
                          onClick={() => handleRemoveImage(index, false)}
                        >
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    ))}
                    {newImages.map((image, index) => (
                      <Box
                        key={`new-${index}`}
                        position="relative"
                        display="inline-block"
                      >
                        <img
                          src={`https://andesconectionsbackend.onrender.com/${image}`}
                          alt={`Nueva Imagen ${index}`}
                          width="100"
                          style={{ borderRadius: "5px" }}
                        />
                        <IconButton
                          size="small"
                          style={{
                            position: "absolute",
                            top: "-5px",
                            right: "-5px",
                            background: "rgba(255, 255, 255, 0.8)",
                          }}
                          onClick={() => handleRemoveImage(index, true)}
                        >
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    ))}
                  </Box>
                </form>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                padding={"3vh"}
                color="white"
                textAlign={"center"}
                variant="h4"
              >
                Lista de Propiedades
              </Typography>
              <Grid container spacing={2}>
                {properties.map((prop) => (
                  <Grid item xs={12} key={prop._id}>
                    <Paper sx={{ padding: 2 }}>
                      <Typography variant="subtitle1">{prop.name}</Typography>
                      <IconButton
                        onClick={() => handleDelete(prop._id)}
                        color="secondary"
                      >
                        <DeleteIcon />
                      </IconButton>
                      <Button onClick={() => setEditingProperty(prop)}>
                        Editar
                      </Button>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        );

      case "contacts":
        return (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography
                padding={"3vh"}
                color="white"
                textAlign={"center"}
                variant="h4"
              >
                Lista de Contactos
              </Typography>
              <Grid container spacing={2}>
                {contacts.map((contact) => (
                  <Grid item xs={12} key={contact._id}>
                    <Paper
                      sx={{
                        padding: 3,
                        borderRadius: 2,
                        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                        backgroundColor: "#f9f9f9",
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          variant="h6"
                          fontWeight="bold"
                          sx={{ color: "#333" }}
                        >
                          {contact.fullName}
                        </Typography>
                        <IconButton
                          onClick={() => handleDelete(contact._id)}
                          color="error"
                          sx={{
                            backgroundColor: "#ffebee",
                            "&:hover": {
                              backgroundColor: "#ffcdd2",
                            },
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                      <Divider sx={{ my: 1 }} />
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr",
                          gap: 1,
                        }}
                      >
                        <Typography variant="body2" sx={{ color: "#555" }}>
                          <strong>Email:</strong> {contact.email}
                        </Typography>
                        <Typography variant="body2" sx={{ color: "#555" }}>
                          <strong>Teléfono:</strong> {contact.phoneNumber}
                        </Typography>
                        <Typography variant="body2" sx={{ color: "#555" }}>
                          <strong>País:</strong> {contact.country}
                        </Typography>
                        <Typography variant="body2" sx={{ color: "#555" }}>
                          <strong>Hora Preferida:</strong>{" "}
                          {contact.preferredContactTime}
                        </Typography>
                        <Typography variant="body2" sx={{ color: "#555" }}>
                          <strong>Método de Contacto:</strong>{" "}
                          {contact.preferredContactMethod}
                        </Typography>
                        <Typography variant="body2" sx={{ color: "#555" }}>
                          <strong>Intereses:</strong>{" "}
                          {contact.propertiesOfInterest}
                        </Typography>
                        <Typography variant="body2" sx={{ color: "#555" }}>
                          <strong>Mensaje:</strong> {contact.message}
                        </Typography>
                        <Typography variant="body2" sx={{ color: "#555" }}>
                          <strong>Consentimiento:</strong>{" "}
                          {contact.agreeToReceive ? "Sí" : "No"}
                        </Typography>
                        <Typography variant="body2" sx={{ color: "#888" }}>
                          <strong>Creado el:</strong>{" "}
                          {new Date(contact.createdAt).toLocaleDateString()}
                        </Typography>
                      </Box>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        );

      case "stats":
        return (
          <Typography
            padding={"3vh"}
            color="white"
            textAlign={"center"}
            variant="h4"
          >
            Estadísticas próximamente...
          </Typography>
        );

      default:
        return null;
    }
  };

  return (
    <Box sx={{ marginTop: "80px" }}>
      <AppBar
        position="static"
        sx={{ backgroundColor: "#556B2F", padding: "2vh" }}
      >
        <Toolbar sx={{ justifyContent: "space-evenly" }}>
          <IconButton
            onClick={() => setView("properties")}
            sx={{ color: "#fff" }}
          >
            <HomeIcon fontSize="large" />
          </IconButton>
          <IconButton
            onClick={() => setView("contacts")}
            sx={{ color: "#fff" }}
          >
            <ContactsIcon fontSize="large" />
          </IconButton>
          <IconButton onClick={() => setView("stats")} sx={{ color: "#fff" }}>
            <BarChartIcon fontSize="large" />
          </IconButton>
        </Toolbar>
      </AppBar>
      {renderView()}
    </Box>
  );
}

export default PanelPage;
